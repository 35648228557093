import { enableProdMode } from '@angular/core';
import { environment } from '@environments/environment';
import { CreateBoApp } from '@core/app/create-bo-app';
import { AppModule } from './app/app.module';


if (environment.production) {
    enableProdMode();
}

new CreateBoApp(AppModule).initApp();
