import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SettingsService } from '@libs/settings';

@Pipe({
  name: 'currencyFormat',
})
export class CurrencyFormatPipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer,
    private settingsService: SettingsService,
  ) {}

  transform(value: any, ...args: any[]): any {
    const format = this.settingsService.currencyFormat;
    if (!format) {
      return value;
    }

    const match = RegExp(/([\.\d]+)/).exec(format);
    if (!match) {
      throw new Error(`Invalid format: ${format}`);
    }
    const numberFormat = match[1];
    const decPos = numberFormat.search(/\./);
    const decNum = decPos > 0 ? numberFormat.substring(decPos + 1).length : 0;
    const options = {
      minimumFractionDigits: decNum,
      maximumFractionDigits: decNum,
      useGrouping: false,
    };
    const num = Intl.NumberFormat('en', options).format(value);
    return this.sanitizer.bypassSecurityTrustHtml(
      format.replace(numberFormat, num),
    );
  }
}
