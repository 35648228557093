import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintControlComponent } from './print-control.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from "@ui/icon/icon.module";

@NgModule({
  declarations: [PrintControlComponent],
  exports: [PrintControlComponent],
  imports: [CommonModule, TranslateModule, IconModule]
})
export class PrintControlModule {}
