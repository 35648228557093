import { Inject, Injectable } from '@angular/core';
import { API_ENDPOINT_DEFAULT } from '../constants';
import { SettingsApiModel } from '@libs/settings';

interface ReplacePathParam {
  [key: string]: string;
}

/**
 * Service for interaction with addresses of API BO methods
 */
@Injectable({
  providedIn: 'root',
})
export class ApiConfigService {
  private urlApi: string;

  constructor(@Inject('API_CONFIG') private apiConfig: SettingsApiModel) {}

  get prefix(): string {
    return this.apiConfig.endpoints?.find(
      (endpoint) => endpoint.name === API_ENDPOINT_DEFAULT,
    )?.url;
  }

  get url(): string {
    return this.urlApi;
  }

  set url(value: string) {
    this.urlApi = value;
  }

  /**
   * Get url template by parameters (Old version of get url API methods)
   */
  getUrl(params: string[], optVersion: string = 'Default'): string {
    return this.prefix + '/' + optVersion + '/' + params.join('/');
  }
  /**
   * Get url by alias
   *
   * @param slug - method code from api.json
   * @param replace - object to replace in URL template
   */
  getMethodUrl(slug: string, replace?: ReplacePathParam): string {
    let pathMethod = this.getMethod(slug);

    if (replace) {
      Object.entries(replace).map((value) => {
        pathMethod = pathMethod.replace('{' + value[0] + '}', value[1]);
      });
    }
    return this.prefix + pathMethod;
  }

  /**
   * Get url template by alias
   *
   * @param slug - method code from api.json
   *@private
   */
  private getMethod(slug: string): string {
    const pathObj = this.apiConfig.methods.find(
      (method) => method.name === slug,
    );
    if (!pathObj) {
      // eslint-disable-next-line no-console
      console.info('!!! Not path for url', slug);
      return '';
    }
    return pathObj?.path;
  }
}
