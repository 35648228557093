import { Pipe, PipeTransform } from '@angular/core';
import { TariffVersionModel } from '@libs/tariff-versions/tariff-version.model';

@Pipe({
  name: 'showTariffVersions',
})
export class ShowTariffVersionsPipe implements PipeTransform {
  transform(value: TariffVersionModel[], ...args: any[]): any {
    if (!args[0] && value) {
      return value.filter((item) => item.editAvl > 0);
    } else {
      return value;
    }
  }
}
