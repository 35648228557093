import { throwError } from 'rxjs';
import { SettingsApiModel, SettingsDBResponse } from '@libs/settings';
import { InjectionToken, isDevMode } from '@angular/core';
import { API_CONFIG_URL, AUTH_TOKEN_DEV_NAME, AUTH_TOKEN_PROD_NAME, DB_CONFIG_URL } from '@libs/constants';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '../../auth/src/app/app.module';



export const API_CONFIG_TOKEN: InjectionToken<SettingsApiModel> = new InjectionToken<SettingsApiModel>('API_CONFIG');

export class PromiseLoad {
    readonly #url: string;
    readonly #authTokenStorageName: string;

    constructor(url: string, tokenStorageName?: string | undefined) {
        this.#authTokenStorageName = tokenStorageName;
        this.#url = url;
    }

    public get<T>(): Promise<T> {
        return new Promise((resolve, reject) => {
            const xhr: XMLHttpRequest = new XMLHttpRequest();
            xhr.open('GET', this.#url, true);

            if (this.#authTokenStorageName)
                xhr.setRequestHeader(
                    'Authorization',
                    window?.localStorage?.getItem(this.#authTokenStorageName),
                );

            xhr.onload = (): void => {
                if (xhr.status === 200) {
                    resolve(JSON.parse(xhr.response));
                } else {
                    reject(throwError('Error load config api'));
                }
            };

            xhr.onerror = (): void => {
                reject(throwError('Error load config api'));
            };

            xhr.send();
        });
    }
}

