import { Injectable } from '@angular/core';
import { MenuItemsModel } from '@libs/menu/menu.model';
import { AdminPrivs } from '@libs/admin-user/admin-user.model';
import { forIn, intersection, reduce } from 'lodash-es';

/**
 * Service for module access checking by user privilege
 */
@Injectable({
    providedIn: 'root',
})
export class ModuleAccessService {
    static checkMenuItemAccess(item: MenuItemsModel, privileges: AdminPrivs[]): boolean {
        if (!item?.objName?.length) {
            return true;
        }

        let accessEnable = false;

        const intersectionPrivsWithModules = intersection(
            item.objName,
            privileges.map((item) => item.objName),
        );

        if (intersectionPrivsWithModules?.length) {
            const intersectionPrivsWithModulesObj = privileges.filter(
                (item) => intersectionPrivsWithModules.indexOf(item.objName) > -1,
            );

            // If exist minimal privilege for vie module (needPrivilegeForView from submenu.ts)
            if (item.needPrivilegeForView?.length) {
                const objPrivsByUser = reduce(intersectionPrivsWithModulesObj, (result: {
                    [key: string]: string[]
                }, value, key) => {
                    (result[value.objName] || (result[value.objName] = [])).push(value.privName);
                    return result;
                }, {});

                forIn(objPrivsByUser, (value, key) => {
                    const moduleInNeed = item.needPrivilegeForView.find(item => item[key]);
                    if (moduleInNeed && intersection(moduleInNeed[key], value)?.length) {
                        accessEnable = true;
                    }
                });
            } else {
                accessEnable = true;
            }
            return accessEnable;
        }
    }
}
