import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currentProtocol',
})
export class CurrentProtocolPipe implements PipeTransform {
  transform(value: string, args?: any): string {
    const protocol = window.location.protocol || 'http:';
    if (value) {
      return protocol + '//' + value;
    }
  }
}
