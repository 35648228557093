import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { LogoModule } from '../logo/logo.module';
import { MenuApplicationModule } from '../menu-application/menu-application.module';
import { UserCardModule } from '../user-card/user-card.module';
import { LangCheckerModule } from '../lang-checker/lang-checker.module';
import { MobileMenuModule } from '../mobile-menu/mobile-menu.module';
import { ClockModule } from '@ui/clock/clock.module';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    LogoModule,
    MenuApplicationModule,
    UserCardModule,
    LangCheckerModule,
    MobileMenuModule,
    ClockModule,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
