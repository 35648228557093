import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar.component';
import { SubmenuModule } from '../submenu/submenu.module';
import { PrintControlModule } from '../print-control/print-control.module';
import { ClockModule } from '@ui/clock/clock.module';
import { ExportControlModule } from '@ui/export-control/export-control.module';

@NgModule({
  declarations: [NavbarComponent],
  imports: [
    CommonModule,
    SubmenuModule,
    PrintControlModule,
    ClockModule,
    ExportControlModule,
  ],
  exports: [NavbarComponent],
})
export class NavbarModule {}
