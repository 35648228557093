import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubmenuComponent } from './submenu.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@libs/pipes';

@NgModule({
  declarations: [SubmenuComponent],
  imports: [CommonModule, RouterModule, PipesModule, TranslateModule],
  exports: [SubmenuComponent],
})
export class SubmenuModule {}
