import { Injectable } from '@angular/core';
import { ConnectableObservable, Observable } from 'rxjs';
import { DatabaseTimeModel } from '@libs/settings';
import { filter, publishReplay, refCount } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiConfigService } from '@libs/api-config/api-config.service';

@Injectable({
  providedIn: 'root',
})
export class DatabaseTimeService {
  private readonly getRequestDbTime: Observable<DatabaseTimeModel> = this.http
    .get<DatabaseTimeModel>(
      this.apiConfig.getMethodUrl('boservice.tariff.getdbtime'),
    )
    .pipe(
      filter((response: DatabaseTimeModel) => !!response),
      publishReplay(1),
      refCount(),
    ) as ConnectableObservable<DatabaseTimeModel>;

  constructor(private http: HttpClient, private apiConfig: ApiConfigService) {}

  get databaseTime(): Observable<DatabaseTimeModel> {
    return this.getRequestDbTime;
  }
}
