import { inject, Injectable } from '@angular/core';
import { StartWithUnit } from '@core/operators/start-with-unit';
import { RxState } from '@rx-angular/state';
import { RxActionFactory, RxActions } from '@rx-angular/state/actions';
import { Observable } from 'rxjs';
import { Language } from '@api/models/UserService/Contract/language';
import { ErrorResponse } from '@core/api/models/error-response.model';
import { UsersService } from '@api/services/users.service';


export interface ILanguageState {
    loading: Record<StartWithUnit, boolean>;
    errors: Record<StartWithUnit, ErrorResponse>;
    languages: Language[];
}

export interface ILanguageAction {
    requestLanguages: Language[];
}

@Injectable({
    providedIn: 'root',
})
export class LanguageState extends RxState<ILanguageState> {
    readonly #usersService: UsersService = inject(UsersService);
    readonly #actions: RxActions<ILanguageAction> = new RxActionFactory<ILanguageAction>().create();

    public readonly loading$: Observable<Record<StartWithUnit, boolean>> = this.select('loading');
    public readonly languages$: Observable<Language[]> = this.select('languages');


    constructor() {
        super();

        this.setDefaultState();
        this.connectSelectors();
    }

    public requestLanguages(): void {
        this.hold(
            this.#usersService.apiUsersListLanguagesGet(),
            this.#actions.requestLanguages,
        );
    }


    private setDefaultState(): void {
        this.set({
            loading: null,
            errors: null,
            languages: null,
        });
    }

    private connectSelectors(): void {
        this.connect('languages', this.#actions.requestLanguages$);
    }
}
