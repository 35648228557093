import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  SettingsDesignLogoModel,
  SettingsModel,
} from '@libs/settings/settings.model';

/**
 * Logo
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ui-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
  /**
   * Settings instance
   */
  @Input() logo: SettingsDesignLogoModel;
  /**
   * Minimal view flag
   * For footer logo variant
   */
  @Input() minimal: boolean;
}
