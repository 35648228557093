import { Injectable } from '@angular/core';
import { ConnectableObservable, Observable, of, Subject } from 'rxjs';
import { catchError, filter, finalize, map, publishReplay, refCount, repeatWhen, tap } from 'rxjs/operators';
import { AdminPrivsValue, AdminUserModel } from './admin-user.model';
import { HttpClient } from '@angular/common/http';
import { ApiConfigService } from '../api-config/api-config.service';
import { JwtService } from '../jwt/jwt.service';
import { ADMIN_USER_PRIVILEGE_DISP, ADMIN_USER_PRIVILEGE_EDIT, ADMIN_USER_PRIVILEGE_SELECT } from '@libs/constants';
import { ErrorResponseModel } from '@libs/error/error.model';

/**
 * System User Service
 */
@Injectable({
    providedIn: 'root',
})
export class AdminUserService {
    /**
     * Auxiliary data update variable
     */
    readonly refreshAdminUserData$: Subject<unknown> = new Subject();
    /**
     * Server pending flag
     */
    readonly pending$: Subject<boolean> = new Subject<boolean>();
    /**
     * Server pending for changePassword flag
     */
    readonly pendingChangePassword$: Subject<boolean> = new Subject<boolean>();
    /**
     * Request for user data
     */
    private readonly requestToGetUser: Observable<AdminUserModel> = this.http
        .get<AdminUserModel>(this.apiConfigService.getUrl(['GetUserData'], 'Login'))
        .pipe(
            finalize(() => this.pending$.next(false)),
            repeatWhen(() => this.refreshAdminUserData$),
            filter((user: AdminUserModel) => !!user),
            tap((user) => (this.jwtService.accessToken = user.usrId.toString())),
            publishReplay(1),
            refCount(),
            catchError(() => of(null)),
        ) as ConnectableObservable<AdminUserModel>;

    constructor(
        private http: HttpClient,
        private apiConfigService: ApiConfigService,
        private jwtService: JwtService,
    ) {
    }

    /**
     * Get current user object
     */
    get user(): Observable<AdminUserModel> {
        return this.getUser();
    }

    get pending(): Observable<boolean> {
        return this.pending$.asObservable();
    }

    get pendingChangePassword(): Observable<boolean> {
        return this.pendingChangePassword$.asObservable();
    }

    public getUser(force?: boolean): Observable<AdminUserModel> {
        if (force) {
            this.pending$.next(true);
            this.refreshAdminUserData$.next(null);
        }
        return this.requestToGetUser;
    }

    /**
     * Change the password of the current user
     *
     * @param oldPassword - old password
     * @param newPassword - new password
     */
    changePassword(oldPassword: string, newPassword: string): Observable<any> {
        this.pendingChangePassword$.next(true);
        return this.http
            .post(
                this.apiConfigService.getMethodUrl('boservice.users.setnewpasswd'),
                {
                    oldPassword,
                    newPassword,
                },
            )
            .pipe(
                finalize(() => this.pendingChangePassword$.next(false)),
                catchError((error: ErrorResponseModel) => this.errorHandler(error)),
            );
    }

    /**
     * Check the edit privilege of the current user in the module
     *
     * @param module - module
     */
    isEditUserPrivilege(module: string): Observable<boolean> {
        return this.getPrivilege(ADMIN_USER_PRIVILEGE_EDIT, module);
    }

    /**
     * Check the select privilege of the current user in the module
     *
     * @param module - module
     */
    isSelectUserPrivilege(module: string): Observable<boolean> {
        return this.getPrivilege(ADMIN_USER_PRIVILEGE_SELECT, module);
    }

    /**
     * Check the display privilege of the current user in the module
     *
     * @param module - module
     */
    isDispUserPrivilege(module: string): Observable<boolean> {
        return this.getPrivilege(ADMIN_USER_PRIVILEGE_DISP, module);
    }

    /**
     * Check user rights for a privilege in a module
     *
     * @param privilege - privilege
     * @param module - module
     *@private
     */
    private getPrivilege(
        privilege: AdminPrivsValue,
        module: string,
    ): Observable<boolean> {
        return this.user.pipe(
            map((user) => {
                if (user) {
                    return (
                        user.privs
                            .filter((priv) => priv.objName === module)
                            .map((item) => item.privName)
                            .indexOf(privilege) > -1
                    );
                } else {
                    return false;
                }
            }),
        );
    }

    private errorHandler(error: ErrorResponseModel): any {
        this.pendingChangePassword$.next(false);
        throw error;
    }
}
