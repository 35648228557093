import { Injectable } from '@angular/core';
import { MenuItemsModel } from './menu.model';
import { ConnectableObservable, Observable, of, Subject } from 'rxjs';
import { filter, publishReplay, refCount, switchMap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiConfigService } from '../api-config/api-config.service';
import { LangService } from '../lang/lang.service';
import { AuthService } from '../auth/auth.service';
import { APP_NAME } from '../constants';

/**
 * Service Menu of system modules
 */
@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private readonly getRequestModules: Observable<MenuItemsModel[]> = this.http
    .get<MenuItemsModel[]>(
      this.apiConfigService.getMethodUrl('boservice.users.listmodules'),
      {
        params: new HttpParams({
          fromObject: {
            appName: APP_NAME,
          },
        }),
      },
    )
    .pipe(
      filter((response: MenuItemsModel[]) => !!response),
      publishReplay(1),
      refCount(),
    ) as ConnectableObservable<MenuItemsModel[]>;

  constructor(
    private http: HttpClient,
    private apiConfigService: ApiConfigService,
    private langService: LangService,
    private authService: AuthService,
  ) {}

  /**
   * Get a list of system modules
   *
   * @returns Observable<MenuItemsModel[]>
   */
  get modules(): Observable<MenuItemsModel[]> {
    return this.authService.isAuth.pipe(
      switchMap((isAuth) => {
        if (isAuth) {
          return this.getRequestModules;
        } else {
          return of([]);
        }
      }),
    );
  }
}
