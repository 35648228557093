import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportControlComponent } from './export-control.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from "@ui/icon/icon.module";

@NgModule({
  declarations: [ExportControlComponent],
  imports: [CommonModule, TranslateModule, IconModule],
  exports: [ExportControlComponent],
})
export class ExportControlModule {}
