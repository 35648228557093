import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputValidationPipe } from './input-validation.pipe';
import { CurrentProtocolPipe } from './current-protocol.pipe';
import { FilterByPropPipe } from './filter-by-prop.pipe';
import { StorageValidatorsPipe } from './storage-validators.pipe';
import { ShowTariffVersionsPipe } from './show-tariff-versions.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { CurrencyFormatPipe } from './currency-format.pipe';
import { DisplayPhonePipe } from './display-phone.pipe';
import { DisplayNamePipe } from './display-name.pipe';
import { SortByPipe } from '@pipes/sort-by.pipe';
import { ProcTimeIndicatorPipe } from './proc-time-indicator.pipe';
import { SafeSrcImagePipe } from "@pipes/safe-src-image.pipe";

@NgModule({
  declarations: [
    SafeHtmlPipe,
    InputValidationPipe,
    CurrentProtocolPipe,
    FilterByPropPipe,
    StorageValidatorsPipe,
    ShowTariffVersionsPipe,
    CurrencyFormatPipe,
    DisplayPhonePipe,
    DisplayNamePipe,
    SortByPipe,
    SafeSrcImagePipe,
  ],
  imports: [CommonModule],
  exports: [
    InputValidationPipe,
    CurrentProtocolPipe,
    FilterByPropPipe,
    StorageValidatorsPipe,
    ShowTariffVersionsPipe,
    SafeHtmlPipe,
    CurrencyFormatPipe,
    DisplayPhonePipe,
    DisplayNamePipe,
    SortByPipe,
    SafeSrcImagePipe,
  ],
})
export class PipesModule {}
