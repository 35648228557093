import { Injectable } from '@angular/core';
import { LangService, SystemLangCode } from '@libs/lang/lang.service';
import { DateFormatModel } from '@services/date-format/date-format.model';
import { DEFAULT_LANG } from '@libs/constants';
import * as moment from 'moment';

const formats: { [k in SystemLangCode]: DateFormatModel } = {
  'en-gb': {
    bsDatePicker: 'DD-MM-YYYY',
    moment: 'DD-MM-YYYY HH:mm',
    angular: 'dd-MM-yyyy HH:mm',
    angularDate: 'dd-MM-yyyy',
  },
  'ru-ru': {
    bsDatePicker: 'DD-MM-YYYY',
    moment: 'DD-MM-YYYY HH:mm',
    angular: 'dd-MM-yyyy HH:mm',
    angularDate: 'dd-MM-yyyy',
  },
  'en-us': {
    bsDatePicker: 'MM-DD-YYYY',
    moment: 'MM-DD-YYYY hh:mm a',
    angular: 'MM-dd-yyyy hh:mm a',
    angularDate: 'MM-dd-yyyy',
  },
};

@Injectable({
  providedIn: 'root',
})
export class DateFormatService {
  private readonly formats: {
    [k in SystemLangCode]: DateFormatModel;
  } = formats;

  constructor(private lanService: LangService) {}

  get format(): DateFormatModel {
    const langCode: SystemLangCode =
      this.lanService.currentLangCode.toLowerCase() as SystemLangCode;
    return this.formats.hasOwnProperty(langCode)
      ? this.formats[langCode]
      : this.formats[DEFAULT_LANG as SystemLangCode];
  }

  prepareDateTimeForDB(dateFrom: Date, timeFrom: Date): string {
    const m = moment(dateFrom);
    return m.format('YYYY-MM-DD') + 'T' + moment(timeFrom).format('HH:mm:ss');
  }

  prepareDateForDB(date: Date | string): string {
    const m = moment(date);
    return m.format('YYYY-MM-DD');
  }
}
