import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserCardComponent } from './user-card.component';
import { UserCardMenuModule } from '../user-card-menu/user-card-menu.module';
import { PipesModule } from '@libs/pipes';

@NgModule({
  declarations: [UserCardComponent],
  imports: [CommonModule, UserCardMenuModule, PipesModule],
  exports: [UserCardComponent],
})
export class UserCardModule {}
