export const PATTERN_EMAIL = `[A-z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_\`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?`;
export const PATTERN_WORDS = '[\\da-zA-Z\\-\\s"&._,+\']*';
export const PATTERN_EMAIL_PATH_SUPPORT = '[^\\s]{3,}$';
export const PATTERN_PHONE = '^[0-9]{1,12}$';
export const PATTERN_NUMERIC = '^-?[0-9]\\d*(\\.\\d*)?$';
export const PATTERN_POSITIVE_NUMERIC = '^[0-9]\\d*(\\.\\d*)?$';
export const PATTERN_INTEGER = '^-?[0-9]\\d*$';
export const PATTERN_PERCENTAGE =
    '^100(\\.0{0,2})? *%?$|^\\d{1,2}(\\.\\d{1,2})? *%?$';
export const PATTERN_PERCENTAGE_INTEGER =
    '^100(\\.0{0,2})? *%?$|^\\d{1,2}? *%?$';
export const PATTERN_POSITIVE_INTEGER_WITHOUT_ZERO = '^[1-9]+[0-9]*$';
export const PHONE_CLEAR_CODE_REGEX = /^0+/;

export const DEFAULT_LANG = 'en-gb';
export const BASE_HOME_URL = '/';
export const LOCAL_LOGIN_URL = '/login';
export const COOKIE_LANG_KEY = 'bo.multipass.lang';
export const COOKIE_ACCEPT_KEY = 'bo.multipass.cookies_accept';
export const APP_NAME = 'bo';

export const SESSION_STORAGE_FILTER_PLACES = 'bo.ref.filterPlaces';
export const SESSION_STORAGE_SELECTED_PROVIDER = 'bo.ref.selectedProviderId';
export const SESSION_STORAGE_SELECTED_ORG = 'bo.ref.selectedOrgId';
export const SESSION_STORAGE_SELECTED_TRM = 'bo.ref.selectedTrmId';
export const SESSION_STORAGE_SELECTED_TARIFF_SERVICE =
    'bo.ref.selectedTariffServiceId';
export const SESSION_STORAGE_SELECTED_ROUTE_SERVICE =
    'bo.ref.selectedRouteServiceId';
export const SESSION_STORAGE_SELECTED_TARIFF_DOMAIN =
    'bo.ref.selectedTariffDomainId';

export const NUMBER_MAX_INT32 = 2147483647;
export const VARCHAR_LENGTH = 255;
export const CHAR_LENGTH = 100;

export const API_CONFIG_URL: string = '/settings/api.json';
export const DB_CONFIG_URL = '/api/App/ListSettings?lang=en-gb';
export const API_ENDPOINT_DEFAULT = 'main';
export const AUTH_TOKEN_DEV_NAME = 'accessToken';
export const AUTH_TOKEN_PROD_NAME = 'xZMcb2EUzZqXQfgxtGp@vdb!K2T';
export const DATE_CALENDAR_FORMAT = 'YYYY-MM-DD';
export const DATE_MOMENT_JS_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';

export const TIME_FORMAT = {
    'ru-ru': 'HH:mm',
    'en-gb': 'HH:mm',
    'en-us': 'hh:mm a',
};

export const GOOGLE_MAPS_API_KEY = 'AIzaSyBW7kV4kjSYtMEfo6TXBTuNywd9KuAg0kQ';
export const GOOGLE_MAPS_STOP_DEFAULT_ZOOM = 1;
export const GOOGLE_MAPS_STOP_DETAIL_ZOOM = 16;

/** Privs code */
export const ADMIN_USER_PRIVILEGE_DISP = 'disp';
export const ADMIN_USER_PRIVILEGE_SELECT = 'select';
export const ADMIN_USER_PRIVILEGE_EDIT = 'edit';

export const DIALOG_PANEL_CLASS = 'dialog-panel--adaptive';

export const DEFAULT_FILE_EXPORT_NAME = 'export.xlsx';
export const DEFAULT_FILE_EXPORT_LOG_NAME = 'errorLog.xlsx';
export const DEFAULT_FILE_EXPORT_SHEET_NAME = 'sheet';

export const TABLE_PAGE_SIZE_OPTIONS_DEFAULT = [20, 50, 100];
