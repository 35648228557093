import { ChangeDetectionStrategy, Component, inject, Inject } from '@angular/core';
import * as XLSX from 'xlsx';
import { DEFAULT_FILE_EXPORT_NAME, DEFAULT_FILE_EXPORT_SHEET_NAME } from '@libs/constants';
import { PushService } from '@core/push/push.service';
import { PushCodeModel } from '@core/push/push-code.model';


/**
 * Control for XLSX export first table on page
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ui-export-control',
    templateUrl: './export-control.component.html',
    styleUrls: ['./export-control.component.scss'],
})
export class ExportControlComponent {
    readonly #push: PushService = inject(PushService);

    constructor(
        @Inject('document') private document: Document,
    ) {
    }

    handlerExport(): void {
        const tablesOnPage = this.document.getElementsByClassName('table');
        if (tablesOnPage?.length) {
            const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(tablesOnPage[0]);
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, DEFAULT_FILE_EXPORT_SHEET_NAME);
            XLSX.writeFile(wb, DEFAULT_FILE_EXPORT_NAME);
        } else {
            this.#push.success(PushCodeModel.NoData);
        }
    }
}
