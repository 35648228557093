import { inject, Injectable } from '@angular/core';
import { ErrorResponseModel } from './error.model';
import { PushService } from '@core/push/push.service';
import { PushCodeModel } from '@core/push/push-code.model';


/**
 * Service for dealing with server errors
 */
@Injectable({
    providedIn: 'root',
})
export class ErrorService {
    readonly #push: PushService = inject(PushService);

    /**
     * Show error message in push
     *
     * @param error - error object
     */
    show(error: ErrorResponseModel): void {
        try {
            this.#push.error(
                error?.errorUserMessage !== 'DefaultErrorMessage'
                    ? error.errorUserMessage as PushCodeModel
                    : PushCodeModel.ErrorServer,
            );
        } catch (e) {
            this.#push.error(PushCodeModel.ErrorServer);
        }
    }
}
