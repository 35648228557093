import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuItemsModel } from '@libs/menu/menu.model';
/**
 * Modules menu lists
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ui-menu-application',
  templateUrl: './menu-application.component.html',
  styleUrls: ['./menu-application.component.scss'],
})
export class MenuApplicationComponent {
  /**
   * Selected code value
   */
  @Input() selected: string = window.location.hostname;
  /**
   * List menu items
   */
  @Input() menuItems: MenuItemsModel[];
}
