<div
  class="badge-user d-flex flex-row flex-nowrap align-items-center position-relative"
  *ngIf="user"
  (click)="onClickCard($event)"
>
  <div class="badge-user__title d-flex flex-column flex-nowrap align-items-end">
    <div class="badge-user__title">
      <span
        tabIndex="0"
        class="pseudo pseudo--border-off pseudo--white"
        (keydown.enter)="showCard = !showCard"
        (click)="showCard = !showCard"
        >{{ user.usrName.substr(0, 1) }}.&nbsp;{{ user.surname }}</span
      >
    </div>
    <div
      class="badge-user__division"
      [innerHTML]="user?.orgName | safeHtml"
    ></div>
  </div>
  <ui-user-card-menu *ngIf="showCard" [user]="user"></ui-user-card-menu>
</div>
