import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpBackend } from "@angular/common/http";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { CookiePolicyTextModule } from '@ui/cookie-policy-text/cookie-policy-text.module';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { StatisticsModule } from '../../../statistics/src/lib/statistics.module';
import { CommonAppComponent } from '@ui/common-app/common-app.component';
import { CommonAppModule } from '@ui/common-app/common-app.module';
import { DefaultPushConfig } from '@core/push/push.config';
import { DomDeps, HttpTranslateLoaderFactory, startupAppConfigs, startupDeps } from '@core/app/app.module.helpers';


@NgModule({
  imports: [
    CommonAppModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpTranslateLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    ToastrModule.forRoot(DefaultPushConfig),
    NgxWebstorageModule.forRoot(),
    CookiePolicyTextModule,
    StatisticsModule,
  ],
  providers: [
    ...DomDeps,
    ...[
      {
        provide: APP_INITIALIZER,
        useFactory: startupAppConfigs,
        deps: startupDeps,
        multi: true,
      },
    ],
  ],
  bootstrap: [CommonAppComponent],
})
export class AppModule {}
