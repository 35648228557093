<menu class="m-0 p-0 menu d-flex flex-row flex-wrap" *ngIf="menuItems">
  <div class="menu__item mr-3" *ngFor="let item of displayMenuItems">
    <a
      class="a--border-off a--white"
      [routerLink]="item.url"
      [routerLinkActive]="'a--active'"
      [attr.title]=" item.mdName | translate"
      >{{ item.mdName | translate }}</a
    >
  </div>
</menu>
