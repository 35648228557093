import { Injectable } from '@angular/core';
import { JwtToken } from './jwt.model';
import { StorageService } from '../storage/storage.service';

/**
 * Service for working with JWT token
 * The system uses a simplified version of JWT without a refresh token
 * The authorization system is described in more detail in the README
 */
@Injectable({
    providedIn: 'root',
})
export class JwtService {
    private storeAccessToken: JwtToken = null;

    constructor(private storageService: StorageService) {
    }

    /**
     * Get token value from local storage
     */
    get accessToken(): JwtToken {
        if (!this.storeAccessToken) {
            this.storeAccessToken = this.storageService.accessToken;
        }
        return this.storageService.accessToken;
    }

    /**
     * Set token value in local storage
     */
    set accessToken(token: JwtToken) {
        this.storeAccessToken = token;
        this.storageService.accessToken = token;
    }
}
