import { Inject, Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment } from '@angular/router';
import { AdminUserModel } from '@libs/admin-user/admin-user.model';
import { AuthService } from '@libs/auth/auth.service';
import { LOCAL_LOGIN_URL } from '@libs/constants';
import { PrivsService } from '@libs/privs/privs.service';
import { SettingsService } from '@libs/settings';

@Injectable({
  providedIn: 'root',
})
export class AuthWithTempPasswordGuard implements CanLoad {
  public constructor(
    private authService: AuthService,
    private privsService: PrivsService,
    private settingsService: SettingsService,
    @Inject('window') private window: Window,
  ) {}

  public canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Promise<boolean> | boolean {
    return new Promise((resolve) => {
      this.authService.isAuthUser.subscribe((user: AdminUserModel) => {
        if (user) {
          resolve(true);
        } else {
          this.loginRedirect();
        }
      });
    });
  }

  private loginRedirect(): void {
    setTimeout(() => this.window.location.assign(LOCAL_LOGIN_URL));
  }
}
