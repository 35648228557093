import { Inject, Injectable } from '@angular/core';
import {
  SettingsDesignModel,
  SettingsModel,
} from './settings.model';
import { WindowExt } from '@core/app/app.module.helpers';


/**
 * Application settings service
 * see README for details
 */
@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  readonly #settings: SettingsModel & SettingsDesignModel = {
    ...{},
    ...this.window.appSettings,
    ...this.window.appDesignSettings,
  };

  constructor(@Inject('window') private window: WindowExt) {}
  /**
   * Get settings app
   */
  public get settings(): SettingsModel & SettingsDesignModel {
    return this.#settings;
  }
  /**
   * Get phone code from settings
   */
  public get phoneCode(): number {
    return this.#settings.phoneCode;
  }
  /**
   * Get currency format from settings
   */
  public get currencyFormat(): string {
    return this.#settings.currencyFormat;
  }
  /**
   * Returns the url of the authorization module
   */
  public get baseUrl(): string {
    const baseDomain: string = this.settings.APP_SETTINGS_BASE_DOMAIN.substr(1);
    return (
      this.window.location.protocol +
      '//' +
      baseDomain +
      (window.location.port ? ':' + window.location.port : '')
    );
  }
}
