import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'inputValidation',
})
export class InputValidationPipe implements PipeTransform {
  errorPrefix = 'global.forms.error.';

  constructor(private translate: TranslateService) {}

  transform(value: string, args?: any): string {
    if (value) {
      if (typeof value === 'object') {
        switch (Object.keys(value)[0]) {
          case 'max':
            return this.errorPrefix + 'common.max';
          case 'min':
          case 'pattern':
            return this.errorPrefix + 'common.incorrect';
          case 'required':
            return this.errorPrefix + 'common.required';
          case 'equal':
            return this.errorPrefix + 'common.equal';
          case 'minlength':
            return this.translate.instant(
              'global.forms.error.common.minLength',
              // @ts-ignore
              { x: value?.minlength?.requiredLength },
            );
          default:
            return this.errorPrefix + [Object.keys(value)[0], args].join('.');
        }
      }
    }
  }
}
