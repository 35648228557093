import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenHttpInterceptor } from './token.http.interceptor';
import { ErrorService } from '../error/error.service';

@NgModule({
  declarations: [],
  imports: [HttpClientModule, CommonModule],
  providers: [
    ErrorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenHttpInterceptor,
      multi: true,
    },
  ],
})
export class JwtModule {}
