import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsDesignLogoModel } from '@libs/settings/settings.model';
import { SettingsService } from '@libs/settings/settings.service';
import { MenuService } from '@libs/menu/menu.service';
import { MenuItemsModel } from '@libs/menu/menu.model';
import { AdminUserModel } from '@libs/admin-user/admin-user.model';
import { AdminUserService } from '@libs/admin-user/admin-user.service';
import { LangModel } from '@libs/lang/lang.model';
import { LangService } from '@libs/lang/lang.service';

/**
 * Header
 */
@Component({
  selector: 'ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  /**
   * Logo header
   */
  logo: SettingsDesignLogoModel =
    this.settingsService.settings?.logo?.srcHeader;
  /**
   * User data
   */
  user: Observable<AdminUserModel> = this.adminUserService.user;
  /**
   * List menu items
   */
  menuItems: Observable<MenuItemsModel[]> = this.menuService.modules;
  /**
   * List languages
   */
  languages: Observable<LangModel[]> = this.langService.languages;
  /**
   * Selected lang code
   */
  selectedLang: string = this.langService.currentLangCode;

  constructor(
    private settingsService: SettingsService,
    private adminUserService: AdminUserService,
    private langService: LangService,
    private menuService: MenuService,
  ) {}

  handlerChangeLang(lang: LangModel): void {
    this.langService.currentLang = lang;
    window.location.reload();
  }
}
