import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import { LangService } from '@libs/lang/lang.service';
import { DatabaseTimeModel } from '@libs/settings';

/**
 * Clock in navbar
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ui-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss'],
})
export class ClockComponent {
  /**
   * Time format
   */
  @Input() format = this.langService.timeFormat;
  /**
   * Ref object
   */
  @ViewChild('clock', { static: true }) clock: ElementRef;

  private minIncrement = 0;

  private intervalId: number;

  constructor(
    private langService: LangService,
    @Inject('window') private window: Window,
  ) {}

  @Input() set time(time: DatabaseTimeModel) {
    if (time) {
      this.showTime(time);
      if (this.intervalId) {
        this.window.clearInterval(this.intervalId);
      }
      this.intervalId = this.window.setInterval(() => {
        this.showTime(time);
      }, 60000);
    }
  }

  private showTime(time: DatabaseTimeModel): void {
    this.clock.nativeElement.innerHTML = time?.localtime
      ? moment(time?.localtime)
          .add(this.minIncrement, 'minute')
          .format(this.format)
      : '';
    this.minIncrement++;
  }
}
