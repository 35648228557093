import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StartPreloaderComponent } from './start-preloader.component';
import { LogoModule } from '@ui/logo/logo.module';
import { PreLoaderModule } from '@ui/pre-loader/pre-loader.module';

@NgModule({
  declarations: [StartPreloaderComponent],
  imports: [CommonModule, LogoModule, PreLoaderModule],
  exports: [StartPreloaderComponent],
})
export class StartPreloaderModule {}
