import { Pipe, PipeTransform } from '@angular/core';
import { PHONE_CLEAR_CODE_REGEX } from '@libs/constants';

@Pipe({
  name: 'displayPhone',
})
export class DisplayPhonePipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      const prefix = value
        .toString()
        .slice(0, 4)
        .replace(PHONE_CLEAR_CODE_REGEX, '');
      return '+' + prefix + value.slice(4);
    } else {
      return '';
    }
  }
}
