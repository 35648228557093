<div
  class="pre-loader d-flex w-100 justify-content-center align-items-center"
  [class.pre-loader--narrow]="narrow"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style="
      margin: auto;
      background: transparent;
      display: block;
      shape-rendering: auto;
    "
    width="201px"
    height="201px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50"
      cy="50"
      r="20"
      stroke-width="1"
      stroke="#eb4c6e"
      stroke-dasharray="31.41592653589793 31.41592653589793"
      fill="none"
      stroke-linecap="round"
      transform="rotate(245.495 50 50)"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1.923076923076923s"
        keyTimes="0;1"
        values="0 50 50;360 50 50"
      ></animateTransform>
    </circle>
  </svg>
</div>
