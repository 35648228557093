import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { PushCodeModel } from '@core/push/push-code.model';


@Injectable({
    providedIn: 'root',
})
export class PushService {
    readonly #translate: TranslateService = inject(TranslateService);
    readonly #push: ToastrService = inject(ToastrService);

    public success(code: PushCodeModel): void {
        this.#push.success(this.#translate.instant(code));
    }

    public error(code: PushCodeModel): void {
        this.#push.error(this.#translate.instant(code));
    }
}
