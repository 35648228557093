<i
  class="pseudo--border-off icon d-print-none"
  [attr.tabindex]="pseudo ? 0 : null"
  [ngClass]="[iconClass, 'icon-' + type]"
  [ngStyle]="styleSelector"
  [class.pseudo]="pseudo"
  [attr.title]="titleText | translate"
  (click)="handlerClick.emit()"
  (keydown.enter)="handlerClick.emit()"
>
</i>
