import { Injectable } from '@angular/core';
import { AdminUserService } from '../admin-user/admin-user.service';
import { Observable } from 'rxjs';
import { AdminPrivs } from '../admin-user/admin-user.model';
import { map } from 'rxjs/operators';

/**
 * System user privilege service
 */
@Injectable({
  providedIn: 'root',
})
export class PrivsService {
  private readonly privs$: Observable<AdminPrivs[]> =
    this.adminUserService.user.pipe(
      map((user) => {
        if (user) {
          return user.privs;
        }
      }),
    );

  constructor(private adminUserService: AdminUserService) {}

  /**
   * Returns a list of privileges of the current system user
   */
  get privs(): Observable<AdminPrivs[]> {
    return this.privs$;
  }
}
