import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { AdminUserModel } from '@libs/admin-user/admin-user.model';
import { Subject } from 'rxjs';

/**
 * User card in the header
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ui-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
})
export class UserCardComponent implements OnInit, OnDestroy {
  /**
   * User data
   */
  @Input() user: AdminUserModel;
  /**
   * User context menu display flag
   */
  showCard = false;
  private destroy$: Subject<unknown> = new Subject<unknown>();
  constructor(private router: Router, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe((event: NavigationEnd) => {
        setTimeout(() => {
          this.showCard = false;
          this.cd.markForCheck();
        }, 0);
      });
  }

  public onClickCard($event: Event): void {
    $event.stopPropagation();
  }

  @HostListener('body:click', ['$event'])
  public applyBodyClick(ev: Event): void {
    this.showCard = false;
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
