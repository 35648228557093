import { Inject, Injectable } from '@angular/core';
import { MenuItemsModel } from '../../../../libs/src/lib/menu/menu.model';
import { SeoPageModel } from './seo.model';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class SeoService {
    readonly #isInit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false,
    );

    constructor(
        @Inject('window') private window: Window,
        private title: Title,
        private router: Router,
    ) {
    }

    get loaded(): Observable<boolean> {
        return this.#isInit.asObservable();
    }

    init(menuItems: MenuItemsModel[]): void {
        const defaultData = this.getRootSeoData(menuItems);
        if (defaultData) {
            this.setMeta(defaultData);
        }
        this.#isInit.next(true);

        let prevPath = window.location.pathname;
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                if (this.router.url && event.urlAfterRedirects !== prevPath) {
                    this.setMeta(defaultData);
                    prevPath = event.urlAfterRedirects;
                }
            });
    }

    setMeta(meta: SeoPageModel): void {
        if (meta?.title) {
            this.title.setTitle(meta.title);
        }
    }

    private getRootSeoData(menuItems: MenuItemsModel[]): SeoPageModel {
        const currentModule = environment.localSettings
            ? menuItems.find(this.getModuleByPort.bind(this))
            : menuItems.find(this.getModuleByPathName.bind(this));

        return currentModule
            ? {
                title: currentModule?.mdName,
            }
            : null;
    }

    private getModuleByPathName(item: MenuItemsModel): boolean {
        return item.url === this.window.location.hostname;
    }

    private getModuleByPort(item: MenuItemsModel): boolean {
        switch (+this.window.location.port) {
            case 4200:
                return item.mdId === 101;
            case 4202:
                return item.mdId === 102;
            case 4208:
                return item.mdId === 103;
            case 4210:
                return item.mdId === 104;
            case 4203:
                return item.mdId === 105;
            case 4204:
                return item.mdId === 106;
            case 4205:
                return item.mdId === 107;
            case 4206:
                return item.mdId === 108;
            case 4207:
                return item.mdId === 109;
            case 4209:
                return item.mdId === 110;
            case 4211:
                return item.mdId === 111;
        }
    }
}
