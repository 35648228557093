<ng-progress></ng-progress>
<div class="layout" *ngIf="languages$ | async; else preloader">
  <router-outlet name="header"></router-outlet>
  <router-outlet name="navbar"></router-outlet>
  <router-outlet></router-outlet>
  <div class="layout__footer mt-auto">
    <router-outlet name="footer"></router-outlet>
  </div>
  <router-outlet name="cookie-message"></router-outlet>
</div>

<ng-template #preloader>
  <ui-start-preloader></ui-start-preloader>
</ng-template>
