import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Start preloader
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ui-start-preloader',
  templateUrl: './start-preloader.component.html',
  styleUrls: ['./start-preloader.component.scss'],
})
export class StartPreloaderComponent {
  constructor() {}
}
