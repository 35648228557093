import { HttpBackend } from '@angular/common/http';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { LangService } from '@libs/lang/lang.service';
import { AdminUserService } from '@libs/admin-user/admin-user.service';
import { DEFAULT_LANG } from '@libs/constants';
import { SettingsDesignModel, SettingsModel, SettingsService } from '@libs/settings';
import { DateFormatService } from '@services/date-format/date-format.service';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale, esLocale, huLocale, ruLocale } from 'ngx-bootstrap/chronos';
import { SeoService } from '@services/seo/seo.service';
import { MenuService } from '@libs/menu/menu.service';


// eslint-disable-next-line @typescript-eslint/naming-convention
export const HttpTranslateLoaderFactory = (_httpBackend: HttpBackend) =>
    new MultiTranslateHttpLoader(_httpBackend, [
        './assets/i18n-shared/',
        './assets/i18n/',
    ]);

export const startupAppConfigs =
    (
        settingsService: SettingsService,
        langService: LangService,
        adminUserService: AdminUserService,
        seo: SeoService,
        menuService: MenuService,
    ) =>
        () => {
            langService.initLang();
            menuService.modules.subscribe((items) => seo.init(items));
            adminUserService.getUser(true).subscribe(
                () => {
                },
                () => {
                    adminUserService.pending$.next(false);
                },
            );
        };

export interface WindowExt extends Window {
    appSettings?: SettingsModel;
    appDesignSettings?: SettingsDesignModel;
}

export const windowFactory = (): WindowExt => window;

export const documentFactory = () => document;

export const startupDeps = [
    SettingsService,
    LangService,
    AdminUserService,
    SeoService,
    MenuService,
];

export const getDatepickerConfig = (
    dateFormatService: DateFormatService,
    localeService: BsLocaleService,
    langService: LangService,
): BsDatepickerConfig => {
    defineLocale('ru', ruLocale);
    defineLocale('es', esLocale);
    defineLocale('hu', huLocale);

    const calendarLang: string[] = ['ru-ru', 'en-gb', 'es-es', 'hu-hu'];
    localeService.use(
        calendarLang.indexOf(langService.currentLangCode.toLowerCase()) > -1
            ? langService.currentLangCode.toLowerCase().split('-')[0]
            : DEFAULT_LANG.split('-')[0],
    );
    return Object.assign(new BsDatepickerConfig(), {
        adaptivePosition: true,
        dateInputFormat: dateFormatService.format.bsDatePicker,
    });
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const BsDatePickerDeps = [
    {
        provide: BsDatepickerConfig,
        useFactory: getDatepickerConfig,
        deps: [DateFormatService, BsLocaleService, LangService],
    },
];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DomDeps = [
    {
        provide: 'document',
        useFactory: documentFactory,
    },
    {
        provide: 'window',
        useFactory: windowFactory,
    },
];
