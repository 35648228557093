import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieMessageComponent } from './cookie-message.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CookieMessageComponent],
  imports: [CommonModule, TranslateModule],
  exports: [CookieMessageComponent],
})
export class CookieMessageModule {}
