import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

/**
 * Icon
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ui-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  /**
   * Pseudo link mode flag
   */
  @Input() pseudo = true;
  /**
   * Additional link class
   */
  @Input() iconClass = '';
  /**
   * Icon code
   */
  @Input() type: string;
  /**
   * Title attribute text
   */
  @Input() titleText: string;
  /**
   * Click handler
   */
  @Output() handlerClick: EventEmitter<unknown> = new EventEmitter<unknown>();

  styleSelector: { [k: string]: string } = null;

  /**
   * Font size icon in rem
   */
  @Input() set fontSize(size: string) {
    this.styleSelector = size ? { 'font-size': size + 'rem' } : null;
  }
}
