import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HeaderModule } from '@ui/header/header.module';
import { FooterModule } from '@ui/footer/footer.module';
import { NavbarModule } from '@ui/navbar/navbar.module';
import { BrowserModule } from '@angular/platform-browser';
import { CookieMessageModule } from '@ui/cookie-message/cookie-message.module';
import { PreLoaderModule } from '@ui/pre-loader/pre-loader.module';
import { JwtModule } from '@libs/jwt/jwt.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StartPreloaderModule } from '@ui/start-preloader/start-preloader.module';
import { NgProgressModule } from "ngx-progressbar";
import { NgProgressHttpModule } from "ngx-progressbar/http";

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    HttpClientModule,
    HeaderModule,
    FooterModule,
    NavbarModule,
    NgProgressModule,
    CookieMessageModule,
    PreLoaderModule,
    JwtModule,
    BrowserAnimationsModule,
    NgProgressHttpModule,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    HttpClientModule,
    HeaderModule,
    FooterModule,
    NavbarModule,
    BrowserModule,
    NgProgressModule,
    CookieMessageModule,
    PreLoaderModule,
    StartPreloaderModule,
    JwtModule,
    BrowserAnimationsModule,
    NgProgressHttpModule,
  ],
})
export class SharedCommonModule {}
