import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileMenuComponent } from './mobile-menu.component';
import { UserCardModule } from '../user-card/user-card.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@libs/pipes';

@NgModule({
  declarations: [MobileMenuComponent],
  imports: [
    CommonModule,
    UserCardModule,
    RouterModule,
    TranslateModule,
    PipesModule,
  ],
  exports: [MobileMenuComponent],
})
export class MobileMenuModule {}
