import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

/**
 * Service for working with external metrics
 */
@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  constructor(private router: Router) {}

  fixHints(): void {
    let prevPath = window.location.pathname;
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (this.router.url && event.urlAfterRedirects !== prevPath) {
          prevPath = event.urlAfterRedirects;
        }
      });
  }

  /**
   * Sends goal achievement events to statistics services
   *
   * @param code - target identifier
   * @param options - auxiliary parameters of the target
   */
  reachGoal(code: string, options?: unknown): void {
    if (environment.production) {
    }
  }
}
