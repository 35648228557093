import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayName',
})
export class DisplayNamePipe implements PipeTransform {
  transform(value: number, ...args: any[]): any {
    if (args[0]) {
      const code = args[1] ? args[1] : 'id';
      const prop = args[2] ? args[2] : 'name';
      // @ts-ignore
      const find = args[0].find((item) => item[code] === value);
      if (find && find.hasOwnProperty(prop)) {
        return find[prop];
      }
    }
  }
}
