import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MenuItemsModel } from '@libs/menu/menu.model';
import { Observable } from 'rxjs';
import { DatabaseTimeModel } from '@libs/settings/settings.model';
import { ActivatedRoute } from '@angular/router';
import { AdminPrivs, AdminUserModel } from '@libs/admin-user/admin-user.model';
import { PrivsService } from '@libs/privs/privs.service';
import { AdminUserService } from '@libs/admin-user/admin-user.service';
import { DatabaseTimeService } from '@services/database-time/database-time.service';

/**
 * Navbar
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ui-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  /**
   * List menu items second level
   */
  items: MenuItemsModel[] = this.route.snapshot.data.subMenuItems || [];
  /**
   * List user privileges
   */
  privs$: Observable<AdminPrivs[]> = this.privsService.privs;
  /**
   * Database time
   */
  time$: Observable<DatabaseTimeModel> = this.databaseTimeService.databaseTime;
  /**
   * User data
   */
  user$: Observable<AdminUserModel> = this.adminUserService.user;

  constructor(
    private databaseTimeService: DatabaseTimeService,
    private privsService: PrivsService,
    private route: ActivatedRoute,
    private adminUserService: AdminUserService,
  ) {}
}
