import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AdminUserModel } from '@libs/admin-user/admin-user.model';
import { MenuItemsModel } from '@libs/menu/menu.model';
import { RouteEntity } from "@core/router/route.entity";

/**
 * List modules (mobile menu)
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ui-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent {
  /**
   * List menu items
   */
  @Input() menuItems: MenuItemsModel[];
  /**
   * User data
   */
  @Input() user: AdminUserModel;

  readonly routeEntity: typeof RouteEntity = RouteEntity;

  isOpen: boolean;
}
