import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonAppComponent } from './common-app.component';
import { SharedCommonModule } from '@libs/shared-common/shared-common.module';


@NgModule({
    declarations: [CommonAppComponent],
    imports: [CommonModule, SharedCommonModule],
    exports: [CommonAppComponent],
})
export class CommonAppModule {
}
