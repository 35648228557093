export enum PushCodeModel {
    SuccessAdd = 'push.success.add',
    SuccessChange = 'push.success.change',
    SuccessDelete = 'push.success.delete',
    SuccessActivate = 'push.success.activate',
    SuccessPasswordChange = 'push.success.password-change',
    SuccessUserRoleAdd = 'push.success.user-role-add',
    SuccessUserRoleDelete = 'push.success.user-role-remove',
    SuccessFileUpload = 'push.success.file-upload',
    SuccessFileUploadWithError = 'push.success.file-upload-with-error',
    NoData = 'global.labels.no_data',
    SuccessValidatorAdd = 'push.success.validator-add',
    SuccessStatus = 'ui.table-uploads.status.success',
    Success = 'push.success.success',
    SuccessChangeStatus = 'push.success.change-status',
    SuccessTransferTicket = 'push.success.transfer-ticket-success',
    SuccessBlockClient = 'push.success.block-client',
    SuccessUnblockClient = 'push.success.unblock-client',
    SuccessPersonalDataSave = 'push.success.personal-data-save',
    ErrorServer =  'push.error.server',
}
