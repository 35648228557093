import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiConfigService } from '../api-config/api-config.service';
import { JwtService } from '../jwt/jwt.service';
import { AdminUserModel } from '../admin-user/admin-user.model';
import { AdminUserService } from '../admin-user/admin-user.service';
import { AuthParamBody } from './auth.model';
import { ErrorResponseModel } from '../error/error.model';
import { environment } from '@environments/environment';

/**
 * Auth service
 */
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private apiPath: string = 'Login';
    /**
     * Server pending flag
     */
    private readonly pending$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private jwtService: JwtService,
        private apiConfigService: ApiConfigService,
        private adminUserService: AdminUserService,
        private http: HttpClient,
    ) {
    }

    /**
     * Server pending flag
     */
    get pending(): Observable<boolean> {
        return this.pending$;
    }

    /**
     * Is auth attribute
     */
    get isAuth(): Observable<boolean> {
        return this.adminUserService.user.pipe(map((data) => !!data));
    }

    /**
     * Auth user object
     */
    get isAuthUser(): Observable<AdminUserModel> {
        return this.adminUserService.user.pipe(map((userData: AdminUserModel) => (userData ? userData : null)));
    }

    /**
     * Login
     */
    login(params: AuthParamBody): Observable<unknown> {
        this.pending$.next(true);
        return this.http
            .post(this.apiConfigService.getUrl(['CheckLogin'], this.apiPath), params)
            .pipe(
                finalize(() => this.pending$.next(false)),
                map((response: AdminUserModel) => {
                    if (environment.localSettings) {
                        this.jwtService.accessToken = response.usrId.toString();
                    }
                    return response;
                }),
                catchError((error: ErrorResponseModel) => this.errorHandler(error)),
            );
    }

    /**
     * Log-out
     */
    logout(): Observable<unknown> {
        return this.http.get(
            this.apiConfigService.getUrl(['Logout'], this.apiPath),
        );
    }

    /**
     * Error handler
     *
     * @param error - error object
     * @private
     */
    private errorHandler(error: ErrorResponseModel): any {
        this.pending$.next(false);
        throw error;
    }
}
