import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Cookie policy detail text
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ui-cookie-policy-text',
  templateUrl: './cookie-policy-text.component.html',
  styleUrls: ['./cookie-policy-text.component.scss'],
})
export class CookiePolicyTextComponent {}
