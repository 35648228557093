import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByProp',
})
export class FilterByPropPipe implements PipeTransform {
  transform<T>(value: T[], ...args: any[]): T[] {
    if (args[2] && value) {
      switch (args[2] as string) {
        case '<':
          // @ts-ignore
          return value.filter((item) => item[args[0]] < args[1]);
        case '>':
          // @ts-ignore
          return value.filter((item) => item[args[0]] > args[1]);
        case '>=':
          // @ts-ignore
          return value.filter((item) => item[args[0]] >= args[1]);
        case '<=':
          // @ts-ignore
          return value.filter((item) => item[args[0]] <= args[1]);
        case '!=':
          // @ts-ignore
          return value.filter((item) => item[args[0]] !== args[1]);
      }
    }

    if (value) {
      // @ts-ignore
      return value.filter((item) => item[args[0]] === args[1]);
    }
  }
}
