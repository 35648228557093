<div class="container-fluid p-md-8 p-4">
  <div class="row">
    <div class="col">
      <h1>COOKIES POLICY</h1>
      <h2>What Are Cookies?</h2>
      <p>
        As&nbsp;is&nbsp;common practice with almost all professional websites
        this site uses cookies, which are tiny files that are downloaded
        to&nbsp;your computer, to&nbsp;improve your experience. This page
        describes what information they gather, how we&nbsp;use it&nbsp;and why
        we&nbsp;sometimes need to&nbsp;store these cookies. We&nbsp;will also
        share how you can prevent these cookies from being stored however this
        may downgrade or &rsquo;break&rsquo; certain elements of&nbsp;the sites
        functionality.
      </p>
      <p>
        For more general information on&nbsp;cookies see the Wikipedia article
        on&nbsp;<a
          class="a--border-off"
          rel="noopener"
          href="https://en.wikipedia.org/wiki/HTTP_cookie"
          target="_blank"
          >HTTP Cookies</a
        >.
      </p>
      <h2>Your Consent</h2>
      <p>
        When you first visit our website, browsing and remaining on&nbsp;our
        website will imply that you agree to&nbsp;our use of&nbsp;cookies
        as&nbsp;explained in&nbsp;this Cookies Policy.
      </p>
      <p>
        You can withdraw your consent at&nbsp;any time by&nbsp;clearing cookies
        from your browser, and you can prevent the setting of&nbsp;cookies
        by&nbsp;adjusting the settings on&nbsp;your browser. See your
        browser&rsquo;s help instructions for how to&nbsp;do&nbsp;this.
        Be&nbsp;aware that disabling cookies will affect the functionality
        of&nbsp;this and many other websites that you visit. Disabling cookies
        will usually result in&nbsp;also disabling certain functionality and
        features of&nbsp;this site. Therefore it&nbsp;is&nbsp;recommended that
        you do&nbsp;not disable cookies.
      </p>
      <h2>How We&nbsp;Use Cookies</h2>
      <p>
        We&nbsp;use cookies for a&nbsp;variety of&nbsp;reasons detailed below.
        Unfortunately in&nbsp;most cases there are no&nbsp;industry standard
        options for disabling cookies without completely disabling the
        functionality and features they add to&nbsp;this site.
        It&nbsp;is&nbsp;recommended that you leave on&nbsp;all cookies
        if&nbsp;you are not sure whether you need them or&nbsp;not in&nbsp;case
        they are used to&nbsp;provide a&nbsp;service that you use.
      </p>
      <h2>The Cookies We&nbsp;Set</h2>
      <p>
        If&nbsp;you create an&nbsp;account with&nbsp;us then we&nbsp;will use
        cookies for the management of&nbsp;the signup process and general
        administration. These cookies will usually be&nbsp;deleted when you log
        out however in&nbsp;some cases they may remain afterwards
        to&nbsp;remember your site preferences when logged out.
      </p>
      <p>
        We&nbsp;use cookies when you are logged in&nbsp;so&nbsp;that we&nbsp;can
        remember this fact. This prevents you from having to&nbsp;log
        in&nbsp;every single time you visit a&nbsp;new page. These cookies are
        typically removed or&nbsp;cleared when you log out to&nbsp;ensure that
        you can only access restricted features and areas when logged&nbsp;in.
      </p>
      <p>
        This site offers live chat services and cookies may be&nbsp;used
        to&nbsp;remember conversation history and whether to&nbsp;show certain
        notifications relating to&nbsp;past conversations.
      </p>
      <p>
        This site offers e-commerce or&nbsp;payment facilities and some cookies
        are essential to&nbsp;ensure that your order is&nbsp;remembered between
        pages so&nbsp;that we&nbsp;can process it&nbsp;properly.
      </p>
      <p>
        In&nbsp;order to&nbsp;provide you with a&nbsp;great experience
        on&nbsp;this site we&nbsp;provide the functionality to&nbsp;set your
        preferences for how this site runs when you use&nbsp;it. In&nbsp;order
        to&nbsp;remember your preferences we&nbsp;need to&nbsp;set cookies
        so&nbsp;that this information can be&nbsp;called whenever you interact
        with a&nbsp;page is&nbsp;affected by&nbsp;your preferences.
      </p>
      <h2>Third Party Cookies</h2>
      <p>
        In&nbsp;some special cases we&nbsp;also use cookies provided
        by&nbsp;trusted third parties. The following section details which third
        party cookies you might encounter through this site.
      </p>
      <p>
        This site uses Google Analytics which is&nbsp;one of&nbsp;the most
        widespread and trusted analytics solution on&nbsp;the web for
        helping&nbsp;us to&nbsp;understand how you use the site and ways that
        we&nbsp;can improve your experience. These cookies may track things such
        as&nbsp;how long you spend on&nbsp;the site and the pages that you visit
        so&nbsp;we&nbsp;can continue to&nbsp;produce engaging content.
      </p>
      <p>
        For more information on&nbsp;Google Analytics cookies, see the official
        <a
          class="a--border-off"
          href="https://www.google.com/analytics"
          target="_blank"
          rel="noopener"
          >Google Analytics page</a
        >.
      </p>
      <p>
        As&nbsp;we&nbsp;sell products it&rsquo;s important for&nbsp;us
        to&nbsp;understand statistics about how many of&nbsp;the visitors
        to&nbsp;our site actually make a&nbsp;purchase and as&nbsp;such this
        is&nbsp;the kind of&nbsp;data that these cookies will track. This
        is&nbsp;important to&nbsp;you as&nbsp;it&nbsp;means that we&nbsp;can
        accurately make business predictions that allow&nbsp;us to&nbsp;monitor
        our advertising and product costs to&nbsp;ensure the best possible
        price.
      </p>
      <p>
        In&nbsp;some cases we&nbsp;may provide you with custom content based
        on&nbsp;what you tell&nbsp;us about yourself either directly
        or&nbsp;indirectly, or&nbsp;by&nbsp;your browsing habits. These types
        of&nbsp;cookies simply allow&nbsp;us to&nbsp;provide you with content
        that we&nbsp;feel may be&nbsp;of&nbsp;interest to&nbsp;you.
      </p>
      <p>
        Several partners advertise on&nbsp;our behalf and affiliate tracking
        cookies simply allow&nbsp;us to&nbsp;see if&nbsp;our customers have come
        to&nbsp;the site through one of&nbsp;our partner sites so&nbsp;that
        we&nbsp;can credit them appropriately and where applicable allow our
        affiliate partners to&nbsp;provide any bonus that they may provide you
        for making a&nbsp;purchase.
      </p>
      <h2>More Information</h2>
      <p>
        Hopefully that has clarified things for you; as&nbsp;was previously
        mentioned if&nbsp;there is&nbsp;something that you aren&rsquo;t sure
        whether you need or&nbsp;not, it&rsquo;s usually safer to&nbsp;leave
        cookies enabled in&nbsp;case it&nbsp;does interact with one of&nbsp;the
        features you use on&nbsp;our site.
      </p>
    </div>
  </div>
</div>
