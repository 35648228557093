<div class="m-header-menu">
  <div class="m-header-menu__icon">
    <i
      class="icon-menu-burger pseudo pseudo--border-off pseudo--white"
      (click)="isOpen = !isOpen"
    ></i>
  </div>
</div>

<div class="m-header-menu__modal position-fixed" *ngIf="isOpen">
  <div class="container-fluid">
    <div
      class="row flex-row justify-content-between align-items-center m-header-menu__header"
    >
      <div class="col">
        <div class="m-header-menu__icon">
          <i
            class="icon-close pseudo pseudo--border-off pseudo--black"
            (click)="isOpen = !isOpen"
          ></i>
        </div>
      </div>
      <div class="col">
        <div class="m-header-menu__user-card mt-2">
          <div class="m-header-menu__user-name">
            {{ user.usrName.substr(0, 1) }}.&nbsp;{{ user.surname }}
          </div>
          <div
            class="m-header-menu__user-org"
            [innerHTML]="user?.orgName | safeHtml"
          ></div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-10">
    <div class="row">
      <div class="col-12 text-center mb-2" *ngFor="let item of menuItems">
        <a
          class="a--border-off m-header-menu__link"
          [href]="item.url | currentProtocol"
          rel="noopener"
          target="_blank"
        >{{ item.mdName }}</a
        >
      </div>
      <div class="col-12 text-center mb-2">
        <a class="a--border-off m-header-menu__link"
          [href]="routeEntity.Root + routeEntity.UserSettings">
          {{ 'global.labels.settings' | translate }}
        </a>
      </div>
      <div class="col-12 text-center">
        <a class="a--border-off m-header-menu__link" [href]="routeEntity.Root + routeEntity.Logout">
          {{ 'ui.user-card-menu.logout' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
