import { Component, inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LangModel } from '@libs/lang/lang.model';
import { LangService } from '@libs/lang/lang.service';
import { StatisticsService } from '../../../../statistics/src/lib/statistics.service';
import { LanguageState } from '@core/localization/language.state';
import { AuthService } from '@libs/auth/auth.service';
import { RxEffects } from '@rx-angular/state/effects';
import { filter } from 'rxjs/operators';

/**
 *  Common bootstrap application component
 */
@Component({
    selector: 'ui-common-app',
    templateUrl: './common-app.component.html',
    styleUrls: ['./common-app.component.scss'],
    providers: [RxEffects]
})
export class CommonAppComponent implements OnInit {

    readonly #authService: AuthService = inject(AuthService);
    readonly #languageState: LanguageState = inject(LanguageState);
    readonly #langService: LangService = inject(LangService);
    readonly #statisticsService: StatisticsService = inject(StatisticsService);
    readonly #effects: RxEffects = inject(RxEffects);

    /**
     * List languages
     */
    public languages$: Observable<LangModel[]> = this.#langService.languages;

    ngOnInit(): void {
        this.#statisticsService.fixHints();

        this.#effects.register(
            this.#authService.isAuth.pipe(filter(Boolean)),
            () => {
                this.#languageState.requestLanguages();
            }
        )

    }
}
