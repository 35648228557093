import { GlobalConfig } from 'ngx-toastr/toastr/toastr-config';

const DEFAULT_PUSH_SHOW_TIME: number = 3000;

export const DefaultPushConfig: Partial<GlobalConfig> = {
    enableHtml: true,
    extendedTimeOut: DEFAULT_PUSH_SHOW_TIME,
    tapToDismiss: false,
    closeButton: false,
    maxOpened: 3,
    positionClass: 'toast-top-right',
};
