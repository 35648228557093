import { Injectable, isDevMode, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AUTH_TOKEN_DEV_NAME, AUTH_TOKEN_PROD_NAME } from '@libs/constants';

/**
 * Service for interacting with local storage for JWT
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private enabled: boolean;
  private accessTokenName = isDevMode()
    ? AUTH_TOKEN_DEV_NAME
    : AUTH_TOKEN_PROD_NAME;
  constructor(
    @Inject(PLATFORM_ID) private platformId: unknown,
    @Inject('window') private window: Window,
  ) {
    this.enabled = isPlatformBrowser(platformId);
  }
  /**
   * Returns the user token
   */
  get accessToken(): string {
    const token = this.getLocal(this.accessTokenName);
    // if (token && token.indexOf('.') !== -1) {
    if (token) {
      return token;
    }
    return null;
  }
  /**
   * Sets the user token
   *
   * @param value - token value
   */
  set accessToken(value: string | null) {
    if (!value) {
      this.deleteLocal(this.accessTokenName);
      return;
    }

    this.setLocal(this.accessTokenName, value);
  }
  deleteLocal(name: string): void {
    if (!this.enabled) {
      return;
    }

    if (this.getLocal(name)) {
      this.window.localStorage.removeItem(name);
    }
  }

  private getLocal(name: string): string | null {
    if (!this.enabled) {
      return null;
    }
    return this.window.localStorage.getItem(name) || null;
  }

  private setLocal(name: string, value: string): void {
    if (!this.enabled) {
      return;
    }
    this.window.localStorage.setItem(name, value);
  }

  private getSession(name: string) {
    if (!this.enabled) {
      return null;
    }

    return this.window.sessionStorage.getItem(name) || null;
  }

  private setSession(name: string, value: string): void {
    if (!this.enabled) {
      return;
    }

    this.window.sessionStorage.setItem(name, value);
  }

  private deleteSession(name: string): void {
    if (!this.enabled) {
      return;
    }

    if (this.getSession(name)) {
      this.window.sessionStorage.removeItem(name);
    }
  }
}
