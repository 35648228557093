import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

/**
 * Print icon control
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ui-print-control',
  templateUrl: './print-control.component.html',
  styleUrls: ['./print-control.component.scss'],
})
export class PrintControlComponent {
  constructor(@Inject('window') private window: Window) {}

  handlerPrint() {
    this.window.print();
  }
}
