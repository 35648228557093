import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { LangModel } from '@libs/lang/lang.model';

/**
 * Language switcher
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ui-lang-checker',
  templateUrl: './lang-checker.component.html',
  styleUrls: ['./lang-checker.component.scss'],
})
export class LangCheckerComponent {
  /**
   * List languages
   */
  @Input() languages: LangModel[];
  /**
   * Selected lang code
   */
  @Input() selected: string;
  /**
   * Change language event
   *
   * @returns Lang data
   */
  @Output() changeLang: EventEmitter<LangModel> = new EventEmitter<LangModel>();

  constructor() {}

  handlerChangeLang(lang: unknown): void {
    this.changeLang.emit(lang as LangModel);
  }
}
