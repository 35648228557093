import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
/**
 * Preloader
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ui-pre-loader',
  templateUrl: './pre-loader.component.html',
  styleUrls: ['./pre-loader.component.scss'],
})
export class PreLoaderComponent {
  /**
   * Narrow variant flag
   */
  @Input() narrow: boolean;
}
