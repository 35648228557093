import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@libs/auth/auth.guard';
import { HeaderComponent } from '@ui/header/header.component';
import { CookiePolicyTextComponent } from '@ui/cookie-policy-text/cookie-policy-text.component';
import { FooterComponent } from '@ui/footer/footer.component';
import { AuthWithTempPasswordGuard } from '@libs/auth/auth-with-temp-password.guard';
import { CookieMessageComponent } from '@ui/cookie-message/cookie-message.component';
import { NavbarComponent } from '@ui/navbar/navbar.component';
import { RouteEntity } from "@core/router/route.entity";

const routes: Routes = [
  {
    path: RouteEntity.Empty,
    component: CookieMessageComponent,
    outlet: 'cookie-message',
  },
  {
    path: RouteEntity.Empty,
    redirectTo: RouteEntity.Applications,
    pathMatch: 'full',
  },
  {
    path: RouteEntity.Applications,
    loadChildren: () =>
      import('./pages/page-applications/page-applications.module').then(
        (m) => m.PageApplicationsModule,
      ),
    canLoad: [AuthGuard],
  },
  {
    path: RouteEntity.Empty,
    component: HeaderComponent,
    outlet: 'header',
  },
  {
    path: RouteEntity.Empty,
    component: NavbarComponent,
    outlet: 'navbar',
  },
  {
    path: RouteEntity.CookiePolicy,
    component: CookiePolicyTextComponent,
  },
  {
    path: RouteEntity.Empty,
    component: FooterComponent,
    outlet: 'footer',
  },
  {
    path: RouteEntity.Logout,
    loadChildren: () =>
      import('@containers/page-logout/page-logout.module').then(
        (m) => m.PageLogoutModule,
      ),
    canLoad: [AuthGuard],
  },
  {
    path: RouteEntity.Login,
    loadChildren: () =>
      import('@containers/page-login/page-login.module').then(
        (m) => m.PageLoginModule,
      ),
  },
  {
    path: RouteEntity.UserSettings,
    loadChildren: () =>
      import('@containers/page-settings/page-settings.module').then(
        (m) => m.PageSettingsModule,
      ),
    canLoad: [AuthWithTempPasswordGuard],
  },
  {
    path: RouteEntity.Error + '/:code',
    loadChildren: () =>
      import('@containers/error-container/error-container.module').then(
        (m) => m.ErrorContainerModule,
      ),
  },
  {
    path: '**',
    redirectTo: RouteEntity.Error + '/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
