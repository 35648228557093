import { Pipe, PipeTransform } from '@angular/core';
import { ValidatorModel } from '@libs/validators/validator.model';

@Pipe({
  name: 'storageValidators',
})
export class StorageValidatorsPipe implements PipeTransform {
  transform(value: ValidatorModel[], ...args: any[]): any {
    if (args[0] && value) {
      return value.filter((item) => item.storage === 1);
    } else {
      return value;
    }
  }
}
