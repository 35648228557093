import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AdminUserModel } from '@libs/admin-user/admin-user.model';
import { RouteEntity } from "@core/router/route.entity";

/**
 * User card context menu
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ui-user-card-menu',
  templateUrl: './user-card-menu.component.html',
  styleUrls: ['./user-card-menu.component.scss'],
})
export class UserCardMenuComponent {
  /**
   * System user data object
   */
  @Input() user: AdminUserModel;

  readonly routeEntity: typeof RouteEntity = RouteEntity;
}
