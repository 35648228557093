import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import {
  SettingsDesignLogoModel,
  SettingsModel,
} from '@libs/settings/settings.model';
import { SettingsService } from '@libs/settings/settings.service';

/**
 * Footer
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ui-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  /**
   * Settings application
   */
  readonly logo: SettingsDesignLogoModel =
    this.settingsService.settings?.logo?.srcFooter;
  /**
   * Year in copyright
   */
  readonly year = new Date().getFullYear();

  constructor(private settingsService: SettingsService) {}
}
